import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SchedulesService {

  constructor(private http: HttpClient) { }

  scheduleByGroupID(token, groupID, startDate?, endDate?): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    if (startDate)
      param = param.append('startDate', startDate);
    if (endDate)
      param = param.append('endDate', endDate);
    return this.http.get<any>(`${environment.apiUrl}/schedules/${groupID}/oncall`, {
      headers: httpHeaders, params: param
    });
  }

  timlineByGroupID(token, groupID, startDate?, endDate?, children?): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    if (startDate)
      param = param.append('startDate', startDate);
    if (endDate)
      param = param.append('endDate', endDate);
    //Ebison on 01-Feb-2022 for Hierarchical calendar
    if (children)
      param = param.append('children', children);
    let en_URI = encodeURI(environment.apiUrl + "/schedules/" + groupID + "/timeline");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders, params: param
    });
  }

  getAllWeekDays(token, startDays, endDays, startHour?, startMinute?, endHour?, endMinute?): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let param = new HttpParams();
    if (startDays)
      param = param.append('start_weekday', startDays);
    if (endDays)
      param = param.append('end_weekday', endDays);
    if (startHour)
      param = param.append('start_hour', startHour);
    if (startMinute)
      param = param.append('start_minute', startMinute);
    if (endHour)
      param = param.append('end_hour', endHour);
    if (endMinute)
      param = param.append('end_minute', endMinute);
    return this.http.get<any>(`${environment.apiUrl}/app/schedules/schedule_weekdays`, {
      headers: httpHeaders, params: param
    });
  }

  getScheduleByGroupID(token, groupID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/schedules/" + groupID);
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  /**
   * This method returns a list of templates
   * @param token 
   * @returns 
   */
  getTemplates(token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/app/shift/templates");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  /**
   * This method returns a specific template
   * @param token 
   * @param templateId 
   * @returns 
   */
  getTemplateById(token, templateId): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/app/shift/templates/" + templateId);
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }

  getScheduleByGroupIDandScheduleID(token, groupID,scheduleID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/schedules/" + groupID + "/" + scheduleID);
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }
  groupsICALExport(token, groupID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
      'Content-type': 'text/csv'
    });
    let en_URI = encodeURI(environment.apiUrl + "/schedules/" + groupID + "/export");
    return this.http.get(en_URI, {
      responseType: 'arraybuffer', headers: httpHeaders
    });
  }

  addShift(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/schedules`, params, {
      headers: httpHeaders,
    });
  }

  deleteSchedules(token, groupID, schedulesID): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/schedules/${groupID}/${schedulesID}`, {
      headers: httpHeaders,
    });
  }

  updateShift(token, groupID, scheduleID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/schedules/${groupID}/${scheduleID}`, params, {
      headers: httpHeaders,
    });
  }

  cloneShift(token, groupID, scheduleID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/schedules/${groupID}/${scheduleID}/clone`, params, {
      headers: httpHeaders,
    });
  }

  getEmailSchedule(token, groupID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/schedules/${groupID}/send_email`, {
      headers: httpHeaders
    });
  }

  updateEmailSchedule(token, groupID, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/schedules/${groupID}/send_email`, params, {
      headers: httpHeaders,
    });
  }

  getWebcalFeedURL(token, groupID) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/schedules/" + groupID + "/webcal");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders
    });
  }
}
