// Angular
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
// Partials for CRUD
import {
ActionNotificationComponent,
DeleteEntityDialogComponent,
FetchEntityDialogComponent,
UpdateStatusDialogComponent
} from '../../../../partials/content/crud';
import { SubmitEntityDialogComponent } from 'src/app/_theme/partials/content/crud/submit-entity-dialog/submit-entity-dialog.component';
import { ToggleEntityDialogComponent } from 'src/app/_theme/partials/content/crud/toggle-entity-dialog/toggle-entity-dialog.component';

export enum MessageType {
Create,
Read,
Update,
Delete
}

@Injectable()
export class LayoutUtilsService {
/**
 * Service constructor
 *
 * @param snackBar: MatSnackBar
 * @param dialog: MatDialog
 */
//------------------------------------------
private refreshScheduleSubjectName = new Subject<any>();
refreshScheduleSend(message: string) { 
	this.refreshScheduleSubjectName.next({ text: message }); 
}
refreshScheduleGet(): Observable<any> { 
	return this.refreshScheduleSubjectName.asObservable();
}
//-------------------------------------------

// Topbar Name Update
private dropdownNameSubject = new Subject<any>();
nameUpdateSend(message: string) { 
	this.dropdownNameSubject.next({ text: message }); 
}
nameUpdateGet(): Observable<any> { 
	return this.dropdownNameSubject.asObservable();
}

constructor(
private snackBar: MatSnackBar,
private dialog: MatDialog
) { }

/**
 * Showing (Mat-Snackbar) Notification
 *
 * @param message: string
 * @param type: MessageType
 * @param duration: number
 * @param showCloseButton: boolean
 * @param showUndoButton: boolean
 * @param undoButtonDuration: number
 * @param verticalPosition: 'top' | 'bottom' = 'top'
 */
showActionNotification(
Message: string,
Type: MessageType = MessageType.Create,
Duration: number = 2000,
ShowCloseButton: boolean = true,
ShowUndoButton: boolean = true,
UndoButtonDuration: number = 3000,
VerticalPosition: 'top' | 'bottom' = 'bottom'
) {
const Data = {
message: Message,
snackBar: this.snackBar,
showCloseButton: ShowCloseButton,
showUndoButton: ShowUndoButton,
undoButtonDuration: UndoButtonDuration,
verticalPosition: VerticalPosition,
type: Type,
action: 'Undo'
};
if (Message.length > 100) {
	Duration = 5000;
} else if (Message.length > 150) {
	Duration = 7000;
}
return this.snackBar.openFromComponent(ActionNotificationComponent, {
duration: Duration,
data: Data,
verticalPosition: VerticalPosition
});
}

/**
	* Showing Confirmation (Mat-Dialog) before Entity Removing
	*
	* @param title: stirng
	* @param description: stirng
	* @param waitDesciption: string
	*/
deleteElement(title: string = '', description: string = '', waitDesciption: string = '') {
return this.dialog.open(DeleteEntityDialogComponent, {
data: { title, description, waitDesciption },
width: '440px'
});
}

toggleElement(title: string = '', waitDesciption: string = '', panelClass: string = '') {
	return this.dialog.open(ToggleEntityDialogComponent, {
		data: { title, waitDesciption },
		width: '440px',
		panelClass: panelClass
	});
}

submitElement(title: string = '', description: string = '', waitDesciption: string = '') {
	return this.dialog.open(SubmitEntityDialogComponent, {
	data: { title, description, waitDesciption },
	width: '440px'
	});
	}
/**
	* Showing Fetching Window(Mat-Dialog)
	*
	* @param _data: any
	*/
fetchElements(Data) {
return this.dialog.open(FetchEntityDialogComponent, {
data: Data,
width: '400px'
});
}

/**
	* Showing Update Status for Entites Window
	*
	* @param title: string
	* @param statuses: string[]
	* @param messages: string[]
	*/
updateStatusForEntities(title, statuses, messages) {
return this.dialog.open(UpdateStatusDialogComponent, {
data: { title, statuses, messages },
width: '480px'
});
}
/**
 * This method, will remove the stale data from localstorage, by referencing most recent data from database
 * @param localStorageKey 
 * @param latestData 
 * @param filterType 
 * @returns 
 */
updateLocalStorageForFilter(localStorageKey: string, latestData: any[], filterType: string): any[] {
	let savedLocalFilterData: any = JSON.parse(localStorage.getItem(localStorageKey));
	let updatedLocalStorage: any[];
	if (filterType == 'group') {
		let missingObjects: any[] = this.findMissingObjects(latestData, savedLocalFilterData?.group);
		if (missingObjects) {
			updatedLocalStorage = savedLocalFilterData?.group.filter(item =>
				!missingObjects.some(missingItem =>
					missingItem.group_id === item.group_id
				)
			);
		}
	}
	return updatedLocalStorage;
}
/**
 * Helper function - Returns the missing objects in latestData
 * @param latestData 
 * @param localStorageList 
 * @returns 
 */
findMissingObjects(latestData: any[], localStorageList: any[]): any[] {
	let missingObjects: any[];
	if (latestData && localStorageList) {
		missingObjects = localStorageList?.filter(item2 => !latestData.some(item1 => item1.group_id === item2.group_id));
	}
	return missingObjects;
}
}
