import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { GroupsService } from '../../../groups-schedule/group-list/group-list.service';
import { UserstorageService } from '../../userstorage/userstorage.service';
import { AuthService } from './auth.service';
import { Location } from '@angular/common';
import { UrlAccessService } from '../../../url-access/url-access.servvice';


@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  abilities: any;
  simplifiedMenuItems: any;
  constructor( private authService: AuthService,  
    private router: Router,
    private aroute: ActivatedRoute,
    private groupService: GroupsService,
    private _userStorageService:UserstorageService,
    private location: Location, private urlAccessService: UrlAccessService ) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      let isAccessPage = await this.groupService.getAbilities(this.getToken()).toPromise();
      this.abilities = isAccessPage[0];
      this.simplifiedMenuItems = this.urlAccessService.getPermissionCheck(this.abilities);
      let accName = this.getSubdomain();
      let urlAccName = this.getSubdomainFromUrl();
      if (accName) { //Check account name is valid and page url is exist
        if (urlAccName.toLowerCase() != accName.toLowerCase()) {
          location.pathname = this.urlAccessService.getActualRedirectUrl(this.abilities, accName, currentUser);
        }
        const hostUrl = window.location.pathname;
        const urlHost = hostUrl.split('/');
        if (urlHost.length > 2) {
          if (urlHost[2].length == 1) {
            const originalRequestUrl = state.url;
            if (this.isOldUrl(originalRequestUrl)) {
              const res = this.getRedirectUrl(originalRequestUrl, urlHost[2])
              location.pathname = res ? res : '/error/404';
            }
          } else if (route.routeConfig.path === '**') {
            location.pathname = '/error/404';
          }
          else {
            let pageUrl = urlHost.length > 2 ? urlHost[2] : '';
            if(pageUrl == 'integrations' || pageUrl == 'administration') { 
              pageUrl = urlHost[3] ? urlHost[3] : pageUrl;
            }
            if (!pageUrl) {
              location.pathname = this.urlAccessService.getActualRedirectUrl(this.abilities, accName, currentUser);
            } else {
              let selectedPage = this.simplifiedMenuItems.find(data => data.page == pageUrl);
              if(selectedPage?.permissions) {
                if (window.history.length > 2) {
                  const urlWithoutQueryParams = window.location.href.split('?')[0];
                  window.history.replaceState({}, document.title, urlWithoutQueryParams);
                  this.location.back();
                  return true
                } else {
                  const urlWithoutQueryParams = window.location.href.split('?')[0];
                  window.history.replaceState({}, document.title, urlWithoutQueryParams);
                  location.pathname = this.urlAccessService.getActualRedirectUrl(this.abilities, accName, currentUser);
                }
              }
            }
          }
        } else {
          location.pathname = this.urlAccessService.getActualRedirectUrl(this.abilities, accName, currentUser);
        }
      }
      return true;
    }
    const currentUrl = state.url;
    //checking app login status to store url path in cache if not login
    const loginFlag = localStorage.getItem('addLoginFlag');
    if(loginFlag=='no'){
      localStorage.setItem('addUrlToCache',currentUrl);
    }else {
      localStorage.setItem('addUrlToCache','');
    }
    this.authService.logoutFromAuthGaurd();
    return false;
  }

  isOldUrl(url: string): boolean {
    return true;
}

getRedirectUrl(url: string, oldURL: string): string {
  let redirectUrl;
    if(oldURL === 'i') {
      redirectUrl = url.replace('/i/','/service-status/incidents/detail/');
    }
    return redirectUrl;
}
  getSubdomain() {
    const loginDt = JSON.parse(localStorage.getItem('token-key'));
    let accountKey = loginDt?.account? loginDt?.account : '';
    // const hostUrl = window.location.pathname;
    // const urlHost = hostUrl.split('/');
    // // let accountKey = "";
    // if (urlHost.length > 0) {
    //   accountKey = urlHost[1];
    // }
    return accountKey;
  }

  getSubdomainFromUrl() {
    const hostUrl = window.location.pathname;
    const urlHost = hostUrl.split('/');
    let accountKey = "";
    if (urlHost.length > 0) {
      accountKey = urlHost[1];
    }
    return accountKey;
  }

  getToken() {
    return this._userStorageService.getToken();
  }
}