import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { SplashScreenService } from './_theme/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd, ActivationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { LayoutService } from './_theme/core';
import { UserstorageService } from './_theme/core/users/userstorage/userstorage.service';
import { Userpilot} from 'userpilot';
import { AbilitiesService } from './_theme/core/abilities/abilities.service';
import { UserHTTPService } from './_theme/core/users/user-list/_services/user-http';

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; 
  public interval: number;
  public loading = false;
  public isRefreshToken = false;
  public isMouseMove = false;
  subdomainValue: any;
  loggedin_userID: any;
  show_tour: boolean;
  show_chat_else: boolean = false;
  show_chat_if: boolean = false;
  show_ops_assist: boolean = false;
  constructor(
    private readonly splashScreenService: SplashScreenService,
    private readonly router: Router,
    private readonly layoutService: LayoutService,
    private readonly userservice: UserstorageService,
    private readonly abilitiesServices : AbilitiesService,
    private readonly _layout: LayoutService,
    private readonly renderer : Renderer2,
    private readonly _userServices : UserHTTPService
  ) {
  }

  ngOnInit() {
    this.initDoc360();
    let token  = localStorage.getItem('token');
    if (token) {
      this.getUserDetails();
    }
    this.getAccountSubject();
    let prev_ev_url;
    this.subdomainValue = this._layout.getSubdomain();
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.userservice.getAccountExpired();
        this.splashScreenService.hide();
        window.scrollTo(0, 0);
        if(this.show_tour){
          Userpilot.reload();
        }
      }
      let path_detail;
      if (event instanceof ActivationStart) {
        let ev: any;
        ev = event.snapshot;
        let ev_url = ev?._routerState?.url;
        let _correctedLastPathIndex = ev?._correctedLastPathIndex;
        this.subdomainValue = this.subdomainValue? this.subdomainValue : 'org';
        if (event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path == event.snapshot.routeConfig.path) {
          path_detail = '/' + event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path;
        } else if ((event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path != event.snapshot.routeConfig.path) && event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path && event.snapshot.routeConfig.path) {
          path_detail = '/' + event.snapshot?.pathFromRoot?.filter(e => e.routeConfig?.path)[1]?.url[0]?.path + '/' + event.snapshot.routeConfig.path
        }
        if (path_detail) {
            prev_ev_url = path_detail;
            datadogRum.startView({
              name: path_detail
            });
        } else {
          let ev_url2 = ev_url; // to skip the else part call for auth module
          ev_url = ev_url?.replace('/' + this.subdomainValue, ''); // ?token= -> added for to restrict mail action url
          if (ev_url && !ev_url2.includes("org") && !ev_url2?.toLowerCase().includes("?token=") && prev_ev_url != ev_url && !event.snapshot.routeConfig?.children && _correctedLastPathIndex != -1) { // to get a back url // "event.snapshot.routeConfig?.children" it skip one loop
            datadogRum.startView({
              name: ev_url
            });
          }
        }
      }
    });
    this.unsubscribe.push(routerSubscription);
  }
 
  loadHubSpotInit(officialemail, token) {

    // Load the HubSpot Conversations script
    const hubSpotScriptId = 'hs-script-loader';

    if (!document.getElementById(hubSpotScriptId)) {
      const hubSpotScript = this.renderer.createElement('script');
      hubSpotScript.src = `https://js.hs-scripts.com/${environment.hubspotId}.js`;
      hubSpotScript.id = hubSpotScriptId;
      hubSpotScript.async = true;
      hubSpotScript.defer = true;
      this.renderer.appendChild(document.head, hubSpotScript);
    }

    (<any>window).hsConversationsSettings = {
      loadImmediately: false,
      identificationEmail: officialemail,
      identificationToken: token
    };

    (<any>window).hsConversationsOnReady = [
      () => {
        if (!(<any>window).HubSpotConversations?.widget?.loaded) {
          (<any>window).HubSpotConversations?.widget?.load();
          (<any>window).HubSpotConversations.widget.loaded = true; // prevent loading again
        }
      },
    ];

    (<any>window).HubSpotConversations?.on('conversationStarted', (payload) => {
      if (payload.conversation.conversationId) {

        const aoHubspotToken = localStorage.getItem('aohubSpot');
        let isTokenExpired = this._userServices.isTokenExpired(aoHubspotToken)

        if (isTokenExpired || !aoHubspotToken) {
          this.generateHubspotVisitorIdentification(officialemail);
        }
      }
    });
  }

  getAccountSubject(){
    this.abilitiesServices.accountSubject.subscribe(installation_type =>{
      if(!installation_type) return;
      if(installation_type == 2) {
        datadogRum.init({
          applicationId: environment.applicationId,
          clientToken: environment.clientToken,
          site: 'datadoghq.com',
          service:'aowebapp',
          env: environment.env,
          // Specify a version number to identify the deployed version of your application in Datadog 
          // version: '1.0.0',
          sessionSampleRate: 100,
          sessionReplaySampleRate: 100,
          trackUserInteractions: true,
          trackResources: true,
          trackLongTasks: true,
          defaultPrivacyLevel:'mask-user-input',
          trackViewsManually: true,
        });

        datadogRum.setUserProperty('account_type', installation_type);

        datadogRum.startSessionReplayRecording();

        let user_storage = localStorage.getItem('userLocalStorageToken');
        let subdomain = this.layoutService.getSubdomain();
        if (subdomain == '') {
          subdomain = localStorage.getItem('subdomain');
        }
        if (user_storage) {
          this.loggedin_userID = JSON.parse(user_storage);
          let ddg_user_id = datadogRum.getUser();
          if (this.loggedin_userID.user_id) {
            if (ddg_user_id.user_id !== this.loggedin_userID.user_id) {
              datadogRum.setUser({ id: this.loggedin_userID.user_id, account: subdomain })
            }
          }
        }
      }
    });
  }

  /**
   * This method returns token for API call
   * @returns 
   */
  getToken() {
    return this.userservice.getToken();
  }

  /**
   *  This method gets the user details using username and sets email in localstorage if email is not available
   */
  getUserDetails() {
    let user_storage = localStorage.getItem('userLocalStorageToken');
    let email_local_storage = localStorage.getItem('email');
    if(user_storage){
      this.loggedin_userID = JSON.parse(user_storage);
    }
    const { user_id } = this.loggedin_userID || {};
    if (!email_local_storage) {
      this._userServices.getUserById(this.getToken(), user_id).subscribe(result => {
        const emailInd = result.contact_methods?.findIndex(i => i.contact_method_name == 'Email-Official');
        if (emailInd >= 0) {
          localStorage.setItem('email', result.contact_methods[emailInd]['email']['email_address']);
          this.userPilotAndHubspotInit(this.loggedin_userID);
        }
      });
    } else {
      this.userPilotAndHubspotInit(this.loggedin_userID);
    }
  }

  userPilotAndHubspotInit(loggedin_userID: any) {
    Userpilot.initialize(environment.show_tour_productid);
    let subdomain = this.layoutService.getSubdomain();
    if(subdomain == ''){
      subdomain = localStorage.getItem('subdomain');
    }
    this.abilitiesServices.abilitiesSubject.subscribe(result => {
      this.show_ops_assist = result?.ops_assist;
      // this.show_ops_assist = false;
      if (!result) return;
      
      const { show_tour, show_chat } = result;
      const { user_id, first_name } = loggedin_userID || {};
      const officialEmail = localStorage.getItem('email');
      const aoHubspotToken = localStorage.getItem('aohubSpot');

      // Handle Userpilot Identification
      if (show_tour === 1 && user_id) {
        this.show_tour = true;
        Userpilot.identify(user_id, {
          name: first_name,
          account: subdomain,
        });
      }
    
      // Handle Chat Display Logic
      if (!this.show_chat_if && show_chat === 1 && !this.show_ops_assist) {
        const isTokenExpired = this._userServices.isTokenExpired(aoHubspotToken);
        this.show_chat_if = true;
        this.show_chat_else = false;
     
        if (isTokenExpired || !aoHubspotToken) {
          this.generateHubspotVisitorIdentification(officialEmail);
        } else {
          this.loadHubSpotInit(officialEmail, aoHubspotToken);
        }
      } else if (!this.show_chat_else && show_chat === 0) {
        
        this.show_chat_if = false;
        this.show_chat_else = true;
    
        // Remove HubSpot widget from UI
        const hubspotWidget = (<any>window).HubSpotConversations;
        hubspotWidget?.widget.remove();
        hubspotWidget?.clear({ resetWidget: true });
      }
    })
  }

  generateHubspotVisitorIdentification(officialemail)
  {
    const params = {
      url: environment.hubspotURL,
      api_token: environment.hubspotToken,
      email: officialemail,
      first_name: this.loggedin_userID.first_name,
      last_name: this.loggedin_userID.last_name
    }
    this._userServices.generateHubspotToken(params).subscribe(
      res => {
        if (res.body && res.status == 200) {
          const token = (res.body as any).token; 
          localStorage.setItem('aohubSpot', token);
          this.loadHubSpotInit(officialemail, token);
        }
      },
      error => {
        console.error('Error occurred:', error);
        console.error('HTTP Status Code:', error.status); // Log the status code of the error
      }
    );
  }

  initDoc360() {
    let code = "(function (w,d,s,o,f,js,fjs) { " +
      "w['JS-Widget']=o;w[o] = w[o] || function () {(w[o].q = w[o].q || []).push(arguments)};" +
      "js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];" +
      "js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);" +
    "} (window, document, 'script', 'mw', 'https://cdn.document360.io/static/js/widget.js')); " +
    "mw('init', { apiKey: 'tusYiC7GcSm7yZIK9pDtyDgzjcBm564URYf1VDFzB2g79XYTJBTzXSULrTwLkoO5k6tNJzSQS6GSWDxVgAgHRWsTPbWqMioXYMgxHWNabvBE0Ky5Eu0lkQ1INmngXyB8CzEVzpdk/1XK3HPSwrxEZQ==' }); ";
    let s = document.createElement("script");
    s.type = "text/javascript";
    s.innerHTML = code;
    let body = document.getElementById("kt_body");
    let layout = document.getElementsByTagName('app-layout')[0];
    body.insertBefore(s, layout);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}

