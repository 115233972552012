import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AdminUserAttributesService {

  constructor(private http: HttpClient) { }

  getAllUserAttributes(token) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/user_attributes`, {
      headers: httpHeaders
    });
  }

  addUserAttribute(token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/user_attributes`, params, {
      headers: httpHeaders,
    });
  }

  updateUserAttribute(token, attributeName, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/user_attributes/${attributeName}`, params, {
      headers: httpHeaders,
    });
  }

  deleteUserAttribute(token, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/user_attributes/${params['attribute_id']}`, {
      headers: httpHeaders,
    });
  }
}


