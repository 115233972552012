<div class="kt-mat-snackbar">
	<!-- This innerHTML will convert message wrapped as html content into HTML String so that we can show in the UI. -->
    <!-- Non-HTML content will be shown as it is. -->
	 
	<div class="kt-mat-snackbar__message">
		<span [innerHTML]="data.message"></span>
	</div>
	<div class="kt-mat-snackbar__btn">
		<button *ngIf="data.showUndoButton" type="button" mat-button color="primary" (click)="onDismissWithAction()">
			Undo
		</button>
	</div>
	<div class="kt-mat-snackbar__close">
		<button *ngIf="data.showCloseButton" 
			mat-icon-button 
			(click)="onDismiss()"
			color="warn">
			<mat-icon>clear</mat-icon>
		</button>
	</div>
</div>
