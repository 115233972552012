import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../../../environments/environment';
import { UserModel } from '../../../user-list/_models/user.models';

@Injectable({
  providedIn: 'root',
})
export class ContactMethodHTTPService {
  constructor(private readonly http: HttpClient) { }

  getUserCmAdd(paramsId, token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users/${paramsId}/contact_methods`, params, {
      headers: httpHeaders,
    });
  }

  getUserCmUpdate(paramsId, token, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<any>(`${environment.apiUrl}/users/${paramsId}/contact_methods/${params['contact_method_name']}`, params, {
      headers: httpHeaders,
    });
  }

  deletecontactMethod(name, token, userid): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}`, {
      headers: httpHeaders,
    });
  }

  deletePushNotificationMethod(key: string, token: string, userid: string): Observable<any> {
    const httpHeaders = new HttpHeaders({
        Authorization: `${token}`,
        'Content-Type': 'application/json'
    });
    const payload = {
        notification_key: key
    };
    const url = `${environment.apiUrl}/users/${userid}/contact_methods/push_notification`;
    return this.http.request<UserModel>('DELETE', url, {
        headers: httpHeaders,
        body: payload
    });
  }

  getnotificationCM(name, token, userid): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times`, {
      headers: httpHeaders,
    });
  }

  updateNotificationCM(name, token, userid, params = {}, id) {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.put<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times/${id}`, params, {
      headers: httpHeaders,
    });
  }

  createNotificationCM(name, token, userid, params = {}): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<any>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times`, params, {
      headers: httpHeaders,
    });
  }

  addnotificationCM(name, token, userid, params): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.post<UserModel>(`${environment.apiUrl}/users/${userid}/contact_methods/${name}/notification_times`, params, {
      headers: httpHeaders,
    });
  }
  
  deleteTimes(name, token, user, id): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.delete<any>(`${environment.apiUrl}/users/${user}/contact_methods/${name}/notification_times/${id}`, { headers: httpHeaders });
  }

  getUserContactMethodNames(token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    return this.http.get<any>(`${environment.apiUrl}/app/user_contact_methods`, {
      headers: httpHeaders
    });
  }

  getUserCM(paramsId, token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `${token}`,
    });
    let en_URI = encodeURI(environment.apiUrl + "/users/" + paramsId + "/contact_methods");
    return this.http.get<any>(en_URI, {
      headers: httpHeaders,
    });
  }

}