import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonUtilsService {

  constructor() { }

  /**
    * User_Story- 2417 -> This function is used to get the image title as replace space with underscore and convert to lowercase
    * @param title 
    * @returns 
    */
  getImageTitle(title: string): string {
    const image_title = title.toLowerCase().replace(/ /g, '_');
    return image_title;
  }
}
